<template>
  <b-row class="py-1 form-ele" :class="positionClass">
      <b-col sm="12" md="6" class="label-cls">
        <label :for="id">{{ label }}</label>
      </b-col>
      <b-col sm="12" md="6" class="control-cls">
        <b-form-input
            :id="id"
            :type="formElement"
            :placeholder="label"
            :name="eleName"
            v-bind:value="value"
            v-on:input="$emit('input', $event)"
            :required="isRequired"
            :class="classCustom"
        ></b-form-input>
        <div v-if="validationMessage" class="invalid-feedback">
          {{ validationMessage }}
        </div>
      </b-col>
  </b-row>
</template>

<script>
export default {
  name: "TextElement",
  data() {
    return {
      inputVal: "ipval"
    }
  },
  props: [
      'id',
    'label',
    'odd',
    'even',
    'name',
    'value',
    'validationMessage',
    'required',
    'email',
    'classCustom'
  ],
  computed: {
    eleName: function () {
      if (this.name !== undefined && this.name !== "") {
        return this.name;
      } else {
        return this.id;
      }
    },
    positionClass: function () {
      return {
        'odd': this.odd !== undefined,
        'even': this.even !== undefined,
      }
    },
    isRequired: function () {
      if (this.required !== undefined) {
        return 'required'
      } else {
        return false;
      }
    },
    formElement: function () {
      if (this.email !== undefined) {
        return "email"
      } else {
        return "text"
      }
    }
  }
}
</script>

<style scoped>

.was-validated .form-control:valid, .form-control.is-valid{
  background-image: none;
}


label {
  color: var(--text-primary-color);
  font-weight: normal;
  font-size: 15px;
  text-align: left;
  float: left;
}

@media (min-width: 544px) {
  label {
    font-size: calc( var(--text-font) - 7px);
    float: inherit;
  }
}

.form-ele.odd {
  background-color: #f8d7cd;
}

.form-ele.even {
  background-color: #fcece8;
}

.form-control:focus {
  border-color: var(--text-secondary-color);
  box-shadow: 0 0 0 0.25rem rgb(253 233 220 / 50%);
}

.invalid-feedback {
  font-size: 0.6em;
}
</style>
