<template>
  <b-container class="panel white feature ipsSpacer_bottom_double px-5 d-flex" fluid>
    <b-row>
      <b-col cols="12">
        <b-row class="mt-5 justify-content-center">
          <b-col sm="12" class="d-lg-none">
            <h2 class="heading_large gs_reveal"><strong>{{ heading }}</strong></h2>
          </b-col>
          <b-col sm="12" lg="10">
            <p class="gs_reveal secture-description" v-html="enhanceDescription(emphasis)"></p>
          </b-col>
          <b-col sm="12" md="1"  class="d-none d-lg-flex">
            <h2 class="heading_large gs_reveal"><strong>{{ heading }}</strong></h2>
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="12" md="10">
            <div class="featured-image-container gs_reveal gs_reveal_fromLeft">
              <img width="100%" :src="imagePath" alt="">
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default {
  name: "CrossSection",
  props: ["heading", "description", "emphasis", "image", "right", "title"],
  computed: {
    imagePath: function () {
      return require("../assets/img/" + this.image);
    },
  },
  mounted() {


    //*************************************************************//
    // Animations //

    function animateFrom(elem, direction) {
      direction = direction || 1;
      var x = 0,
          y = direction * 100;
      if (elem.classList.contains("gs_reveal_fromLeft")) {
        x = -100;
        y = 0;
      } else if (elem.classList.contains("gs_reveal_fromRight")) {
        x = 100;
        y = 0;
      }
      elem.style.transform = "translate(" + x + "px, " + y + "px)";
      elem.style.opacity = "0";
      gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto"
      });
    }

    function hide(elem) {
      gsap.set(elem, {autoAlpha: 0});
    }

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () {
          animateFrom(elem)
        },
        onEnterBack: function () {
          animateFrom(elem, -1)
        },
        onLeave: function () {
          hide(elem)
        } // assure that the element is hidden when scrolled into view
      });
    });
  },
  methods: {
    enhanceDescription: function (st) {
      let htmlRep = "<span class='emphasis-text' style='color: var(--text-secondary-color)'>" + st + "</span>";
      const result = this.description.split('--emp-text--').join(htmlRep)
      return result;
    }
  }
}
</script>

<style scoped>
.white {
  background-color: white;
}

.gs_reveal {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
}

h2.heading_large {
  text-align: left;
  font-size: 1.8em;
}

.emphasis-text {
  color: var(--text-secondary-color);
}

img {
  max-width: 100%;
  width: 100vh;
}

@media (min-width: 768px) {
  img {
    width: 100%;
  }
}
</style>
