<template>

  <b-container fluid class="description panel blue px-5" id="id-home">
    <div class="video-container d-none d-md-flex" :class="fadeOut" >
      <video autoplay muted id="introVideo" :class="fadeOut"  class="d-none d-md-flex">
        <source src="../assets/intro.mp4" type="video/mp4">
      </video>
    </div>
    <b-row class="gs_reveal secture-description" align-v="center" :class="fadeIn">
      <b-col lg="6">
        <p>{{ $t("page1.description") }}
        </p>
      </b-col>
    </b-row>

    <div :class="fadeIn" class="scroll-down">{{ $t("page1.scrollDown") }}
      <div class="arrow"></div>
    </div>

    <b-alert
        :show=cookieAlert
        class="position-fixed fixed-bottom m-0 rounded-0 cookie-notice"
        style="z-index: 2000;"
        variant="warning"
        dismissible
    >
      We use cookies to make this site work properly and to analyse traffic. By continuing to browse the site, you agree to use of cookies.
    </b-alert>
  </b-container>
</template>

<script>
import {gsap} from "gsap";

export default {
  name: "FullImgSection",
  data() {
    return {
      fadeOut: 'opaque',
      fadeIn: 'transparent',
      cookieAlert: false,
    }
  },
  mounted() {
    let arrow = document.querySelector('.arrow');
    if (arrow) {
      gsap.to(arrow, {y: 12, ease: "power1.inOut", repeat: -1, yoyo: true});
    }

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view
      var x = 0,
          y = 200;
      elem.style.opacity = "0";
      elem.style.transform = "translate(" + x + "px, " + y + "px)";
      gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto"
      });
    });

    function hide(elem) {
      gsap.set(elem, {autoAlpha: 0});
    }

    if(window.matchMedia('(min-width: 768px)').matches) {
      setTimeout(() => { //OR =>
        this.fadeOut = 'transparent';
        this.fadeIn = 'opaque';
        this.cookieAlert = true;
      }, 5300);
    } else {
      this.fadeIn = 'opaque';
      this.cookieAlert = true;
    }
  }
}
</script>

<style scoped>
.secture-description {
  color: #323c50;
  font-weight: normal;
  height: 100%;
}

.blue {
  background-color: #ffffff;
  background-image: url("../assets/img/Picture1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* SCROLL DOWN */
.scroll-down {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  font-weight: 400;
  text-transform: uppercase;
  font-size: 16px;
  overflow: visible;
  color: #323c50;
}

.scroll-down .arrow {
  position: relative;
  top: -4px;
  margin: 0 auto;
  width: 20px;
  height: 20px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj4KPHBhdGggZmlsbD0iYmxhY2siIGQ9Ik00ODMuMiwxOTIuMmMtMjAuNS0yMC41LTUzLjUtMjAuOC03My43LTAuNkwyNTcsMzQ0LjFMMTA0LjUsMTkxLjZjLTIwLjItMjAuMi01My4yLTE5LjktNzMuNywwLjYKCWMtMjAuNSwyMC41LTIwLjgsNTMuNS0wLjYsNzMuN2wxOTAsMTkwYzEwLjEsMTAuMSwyMy40LDE1LjEsMzYuOCwxNWMxMy4zLDAuMSwyNi43LTQuOSwzNi44LTE1bDE5MC0xOTAKCUM1MDMuOSwyNDUuNyw1MDMuNywyMTIuNyw0ODMuMiwxOTIuMnoiLz4KPC9zdmc+);
  background-size: contain;
}

.video-container {
  display: flex;
  align-content: center;
  height: 100%;
  width: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

#introVideo {
  background-color: white;
  width: 100%;
  object-fit: cover;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.transparent {
  opacity: 0 !important;
}

.opaque {
  opacity: 1 !important;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.cookie-notice {
  font-size: 12px;
  padding: 0.75rem 1.25rem;
}
</style>
