import Vue from 'vue';
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
    en: {
        page1: {
            description: "Our mission is to unify global physical security operations under a common delivery platform to facilitate access and site transparency for Chief Security Officers.",
            scrollDown: "Scroll Down"
        },
        page2: {
            title: "Service",
            description: "Secture 360™ is an innovative service delivery model --emp-text-- powering physical security for law enforcement, government agencies, and critical infrastructure sites.",
            emphasis: "currently",
            image: "Picture3.png"
        },
        page3: {
            title: "Lower Cost",
            description: "Using --emp-text-- technology and process automation, we can vastly improve site operations at a lower price point.",
            emphasis: "proprietary",
            image: "Picture4.png"
        },
        page4: {
            title: "KPI",
            description: "By automating almost --emp-text-- common to the guarding industry, we can measure and manage global physical security operations using standardized key performance indicators.",
            emphasis: "every process",
            image: "Picture5.png"
        },
        page5: {
            title: "Timely",
            description: "Through our integrated data architecture, we can aggregate information into a --emp-text-- view of site performance.",
            emphasis: "real-time",
            image: "img.png",
            subTitle: "Site Specific Dashboards",
            subTag: "Drill Down Capabilities"
        },
        page6: {
            title: "Manage",
            description: "Secture 360™ enables you to objectively manage contract compliance with an unparalleled degree of site --emp-text--.",
            emphasis: "transparency",
            image: "Picture6.png",
            subTitle: "Contract Compliance",
            subTag: "Measure And Manage"
        },
        page7: {
            title: "Access",
            description: "Clients can access site information through their PC or --emp-text--.",
            emphasis: "mobile device",
            image: "Picture7.png",
            subTitle: "Mobile Access",
            subTag: "Site Transparency Through Mobile Device"
        },
        page8: {
            title: "Demo",
            description: "To schedule a demo, please complete the following form or contact us at --emp-text--",
            emphasis: "<a style='text-decoration: none; color: var(--text-secondary-color)' href='mailto:inquiries@secture360.ca'>inquiries@secture360.ca</a>",
        }
    },
}

const i18n = new VueI18n({
    locale: 'en', // set locale
    messages, // set locale messages
});

export default i18n
