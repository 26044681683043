<template>
  <b-row align-v="center" cols="12" class="flex-grow-1 demo-row">

    <b-col sm="12" class="contact-header gs_reveal gs_reveal_fromLeft">
      Contact Us
    </b-col>
    <b-col v-if="submitSuccess" sm="12" class="success-message gs_reveal gs_reveal_fromLeft">
      Successfully Submitted
    </b-col>
    <form
        id="contact-form"
        @submit="checkForm"
        action=""
        method="post"
        novalidate
        :class="wasValidated"
    >
      <b-row cols="12">
        <b-col align-v="center" sm="12">
          <div class="demo featured-image-container gs_reveal gs_reveal_fromRight">

            <!--            <div v-if="errors.length" class="err-div">-->
            <!--              <b>Please correct the following error(s):</b>-->
            <!--              <ul>-->
            <!--                <li v-bind:key="error" v-for="error in errors">{{ error }}</li>-->
            <!--              </ul>-->
            <!--            </div>-->
            <TextElement
                id="fname"
                label="First Name"
                :validation-message="validationMessage.fname"
                v-model="fname"
                odd
                required
            ></TextElement>
            <TextElement
                id="lname"
                label="Last Name"
                v-model="lname"
                even
            ></TextElement>
            <TextElement
                id="company"
                label="Company"
                v-model="company"
                :validation-message="validationMessage.company"
                required
                odd
            ></TextElement>
            <TextElement
                id="title"
                label="Title"
                v-model="title"
                even
            ></TextElement>
            <TextElement
                id="email"
                label="Email"
                v-model="email"
                :validation-message="validationMessage.email"
                :class-custom="validClass"
                required
                odd
                email
            ></TextElement>
            <TextElement
                id="phone"
                label="Phone"
                v-model="phone"
                even
            ></TextElement>
            <TextElement
                id="pmoc"
                label="Preferred Method Of Contact"
                v-model="pmoc"
                odd
            ></TextElement>
          </div>
        </b-col>
      </b-row>
      <b-row cols="12" class="button-row">
        <b-button
            squared
            type="submit"
            class="submit-btn"
            block
            variant="primary"
            :disabled="submitDisabled"
        >
          Submit
        </b-button>
      </b-row>
    </form>
  </b-row>
</template>

<script>
import TextElement from "./TextElement";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import axios from "axios";

export default {
  name: "ContactForm",
  created() {

  },
  data() {
    return {
      errors: [],
      payload: {},
      validationMessage: {
        'fname': "First Name is required",
        'company': "Company is required",
        'email': "Email is required and must be valid"
      },
      config: {
        method: 'post',
        // url: "",
        url: "https://ovkfupho7e.execute-api.us-east-2.amazonaws.com/dec/contact-us", //https://ovkfupho7e.execute-api.us-east-2.amazonaws.com/dec/contact-us
        responseType: 'json',
        dataType: "json",
        crossDomain: "true",
        contentType: "application/json; charset=utf-8",
      },
      fname: null,
      lname: "",
      title: "",
      phone: "",
      email: null,
      company: null,
      pmoc: "",
      submitDisabled: false,
      submitSuccess: false
    }
  },
  components: {TextElement},
  mounted() {
    function animateFrom(elem, direction) {
      direction = direction || 1;
      var x = 0,
          y = direction * 100;
      if (elem.classList.contains("gs_reveal_fromLeft")) {
        x = -100;
        y = 0;
      } else if (elem.classList.contains("gs_reveal_fromRight")) {
        x = 100;
        y = 0;
      }
      elem.style.transform = "translate(" + x + "px, " + y + "px)";
      elem.style.opacity = "0";
      gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto"
      });
    }

    function hide(elem) {
      gsap.set(elem, {autoAlpha: 0});
    }

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () {
          animateFrom(elem)
        },
        onEnterBack: function () {
          animateFrom(elem, -1)
        },
        onLeave: function () {
          hide(elem)
        } // assure that the element is hidden when scrolled into view
      });
    });

  },
  computed: {
    wasValidated: function () {
      if (this.errors.length > 0) {
        return "was-validated";
      } else {
        return "needs-validation";
      }
    },
    validClass: function () {
      if (this.errors.includes(this.validationMessage.email)) {
        return 'is-invalid';
      } else {
        return '';
      }
    }
  }
  ,
  methods: {
    checkForm: function (e) {
      this.submitDisabled = true;
      this.submitSuccess = false;

      e.preventDefault();

      this.errors = [];

      if (!this.fname) {
        this.errors.push(this.validationMessage.fname);
      }
      if (!this.company) {
        this.errors.push(this.validationMessage.company);
      }

      let reeamil = /^([\w-.]+@([\w-]+\.)+[\w-]{2,12})?$/;
      if (!this.email) {
        this.errors.push(this.validationMessage.email);
      } else if (!reeamil.test(this.email)) {
        this.errors.push(this.validationMessage.email);
      }

      if(this.errors.length > 0) {
        this.submitDisabled = false;
        return;
      }

      if (
          this.fname &&
          this.company &&
          this.email
      ) {

        this.payload = {
          fname: this.fname,
          lname: this.lname,
          title: this.title,
          phone: this.phone,
          email: this.email,
          company: this.company,
          pmoc: this.pmoc,
        }

        this.config.data = JSON.stringify(this.payload);
        axios(this.config)
            .then((response) => {
              this.info = response
              this.submitDisabled = false;
              this.submitSuccess = true;
            })
            .catch((reason) => {
              console.log(reason);
              this.submitDisabled = false;
            })
      }
    }
  }
}
</script>

<style scoped>
.form-ele > div {
  text-align: right;
}

h2.heading_large {
  text-align: left;
  font-size: 1.8em;
}

.emphasis-text {
  color: var(--text-secondary-color);
}

.contact-header {
  color: white;
  text-align: left;
  background-color: var(--text-primary-color);
  padding: 5px 15px;
  font-weight: normal;
  font-size: 0.7em;
}

.contact-header-row {
  max-width: inherit;
}

.submit-btn {
  width: 100%;
  background-color: var(--text-secondary-color);
  border-color: var(--text-secondary-color);
}

.submit-btn:focus, .submit-btn:active {
  color: #fff;
  background-color: var(--text-secondary-color);
  border-color: var(--text-primary-color);
  box-shadow: 0 0 0 0.25rem rgb(253 233 220 / 50%);
}

.submit-btn:hover {
  background-color: var(--text-primary-color);
  border-color: var(--text-primary-color);
}

.submit-btn:focus:active {
  color: #fff;
  background-color: var(--text-primary-color);
  border-color: var(--text-primary-color);
  box-shadow: 0 0 0 0.25rem rgb(253 233 220 / 50%);
}

.btn-primary:disabled, .btn-primary.disabled{
  background-color: var(--text-primary-color);
  border-color: var(--text-primary-color);
}

.err-div {
  background-color: #fcece8;
  color: red;
  font-size: small;
}

.success-message {
  background-color: #fcece8;
  color: #198754;
  font-size: 1.2rem;
}

.emphasis-text a {
  text-decoration: none;
  color: inherit;
}

.button-row {
  margin-top: 5px;
}

.demo-row {
  max-height: 70vh;
  overflow-y: scroll;
}

@media (min-width: 768px) {
  .demo-row {
    max-height: 90vh;
    overflow-y: auto;
  }
}
</style>
