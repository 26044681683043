import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "./i18n";
import VueGtag from "vue-gtag";

import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {
  LayoutPlugin,
  NavbarPlugin,
  FormInputPlugin,
  ButtonPlugin,
  FormGroupPlugin,
  AlertPlugin
} from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false


Vue.use(LayoutPlugin)
Vue.use(NavbarPlugin)
Vue.use(FormInputPlugin)
Vue.use(ButtonPlugin)
Vue.use(FormGroupPlugin)
Vue.use(AlertPlugin)

Vue.use(VueGtag, {
  config: { id: "G-RTJNLJEZVH" }
});

gsap.registerPlugin(ScrollTrigger)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
