<template>
  <b-row align-v="center" align-h="end" class="subtitle-row">
    <b-col cols="12" md="10" class="subtitle-col">
      <div class="subtitle">
        <div class="subtitle-main">
          <p class="sub-p main-p">{{ main }}</p>
        </div>
        <div class="subtitle-tag">
          <p class="sub-p tag-p">{{ tag }}</p>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SubHeading",
  props: ["main", "tag"]
}
</script>

<style scoped>

.subtitle {
  text-align: right;
  width: fit-content;
  float: right;
  margin-top: 20px;
  font-size: calc(var(--text-mobile-font) - 8px);
}

.subtitle-row,.subtitle-col {
  padding-right: 0px;
}

@media (min-width: 375px) {
  .subtitle {
    font-size: var(--text-mobile-font);
  }
}

@media (min-width: 544px) {
  .subtitle {
    font-size: var(--text-font);
  }
}

.subtitle-main {
  color:  var(--text-primary-color);
}
.subtitle-tag {
  color:  var(--text-secondary-color);
}
.sub-p {
  padding-left:  5px;
}
.main-p {
  margin-bottom: 0px;
  border-bottom: 0.5px solid var(--text-primary-color);
}
.tag-p {
  font-weight: lighter;
  border-top: 0.5px solid var(--text-primary-color);
}



</style>
