<template>
  <div id="nav" class='main-tool-bar'>
    <!--      <router-link to="/about">About</router-link> |-->
    <!--      <router-link to="/about">Solution Overview</router-link> |-->
    <!--      <router-link to="/about">Contact Us</router-link> |-->
    <!--      <router-link to="/about">Login</router-link>-->
    <b-navbar toggleable="sm">


      <b-navbar-brand  >
        <a @click="scrollTo('id-home')">
          <img src="../assets/img/secture360WithInnovationTag.svg" alt="" :class="fadeIn">
        </a>
      </b-navbar-brand>
      <b-collapse id="nav-text-collapse" is-nav >
        <b-navbar-nav class=" align-items-center" :class="fadeIn">
          <b-nav-item :class="hideLinks" class="nav-about" @click="scrollTo('id-home')">About</b-nav-item>
          <b-nav-item :class="hideLinks" class="nav-overview" @click="scrollTo('id-service')">Solution Overview</b-nav-item>
          <b-nav-item :class="hideLinks" class="nav-contact" @click="scrollTo('id-access')">Contact Us</b-nav-item>
          <b-nav-item class="nav-login" href="https://portal.secture360.ca">
            <b-button class="login-btn" squared block variant="primary">
              Login
            </b-button>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default {
  name: "Header",
  data() {
    return {
      fadeIn: 'transparent',
    }
  },
  mounted() {

    if(window.matchMedia('(min-width: 768px)').matches) {
      setTimeout(() => { //OR =>
        this.fadeIn = 'opaque';
      }, 5300);
    } else {
      this.fadeIn = 'opaque';
    }

    ScrollTrigger.create({
      start: 'top -80',
      end: 99999,
      toggleClass: {className: 'main-tool-bar--scrolled', targets: '.main-tool-bar'}
    });

    ScrollTrigger.create({
      start: 'top',
      end: 99999,
      toggleClass: {className: 'inactive', targets: '.nav-about'}
    });

    ScrollTrigger.create({
      start: 'top top',
      end: 4500,
      toggleClass: {className: 'focus', targets: '.nav-overview'}
    });

    ScrollTrigger.create({
      start: 4500,
      end: 99999,
      toggleClass: {className: 'focus', targets: '.nav-contact'}
    });
  },
  methods: {
    scrollTo: function (selector) {
      const yOffset = -50;
      const element = document.getElementById(selector);
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      console.log(element.getBoundingClientRect().top);
      if(selector === 'id-home' || Math.abs(element.getBoundingClientRect().top) > 10) {
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    }
  },
  computed: {
    hideLinks() {
      return (this.$route.name==="Privacy") ? 'hide-link' : '';
    }
  },
  watch: {
    $route: function(from) {
      if(from.name === "Privacy") {
        this.fadeIn = 'opaque';
      }
    }
  }
}
</script>

<style scoped>

.main-tool-bar {
  height: 100px;
  background: rgba(255, 255, 255, 0);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  transition: ease 0.4s;
  z-index: 10;
}

.main-tool-bar--scrolled {
  height: 80px;
  background: rgba(255, 255, 255, 1);
}

.navbar {
  width: 100%;
  background: rgba(255, 255, 255, 0);
}

#nav a {
  color: var(--text-primary-color);
}

.navbar img {
  height: 60px;
  margin: 5px 5px;
  transition: ease 0.4s;
}


#nav-text-collapse {
  justify-content: end;
}

@media (min-width: 1024px) {
  #nav-text-collapse ul {
    white-space: nowrap;
  }
}

@media (min-width: 1440px) {
  #nav-text-collapse ul li {
    margin: auto 1vh;
    font-size: 20px;
  }
}

.main-tool-bar--scrolled .navbar img {
  margin-top: 3vh;
  transition: ease 0.4s;
  height: 55px;
}

@media (min-width: 544px) {
  .navbar img {
    height: 7vw;
    margin: 5px 5px;
    transition: ease 0.4s;
  }

  .main-tool-bar--scrolled .navbar img {
    margin: 5px 5px;
    transition: ease 0.4s;
    height: 7vw;
  }
}

@media (min-width: 769px) {
  .navbar img {
    height: 100px;
  }
  .main-tool-bar--scrolled .navbar img {
    height: 80px;
  }

}

.transparent {
  opacity: 0 !important;
}

.opaque {
  opacity: 1 !important;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.login-btn {
  background-color: var(--text-secondary-color);
  border-color: var(--text-secondary-color);
  padding: 0.375rem 2.75rem
}

.login-btn:focus, .login-btn:active {
  color: #fff;
  background-color: var(--text-secondary-color);
  border-color: var(--text-primary-color);
  box-shadow: 0 0 0 0.25rem rgb(253 233 220 / 50%);
}

.login-btn:hover {
  background-color: var(--text-primary-color);
  border-color: var(--text-primary-color);
}

.login-btn:focus:active {
  color: #fff;
  background-color: var(--text-primary-color);
  border-color: var(--text-primary-color);
  box-shadow: 0 0 0 0.25rem rgb(253 233 220 / 50%);
}

.btn-primary:disabled, .btn-primary.disabled{
  background-color: var(--text-primary-color);
  border-color: var(--text-primary-color);
}

.focus a {
  color: var(--text-secondary-color) !important;
}

.nav-about a {
  color: var(--text-secondary-color) !important;
}

.inactive a {
  color: var(--text-primary-color) !important;
}

.hide-link {
  display: none;
}


</style>
