<template>
  <b-container
      class="panel white feature ipsSpacer_bottom_double px-5 d-flex"
      fluid
      :id="idFormatted"
  >
    <b-row align-v="center">
      <b-col v-if="imagePosLeft" sm="12" md="7">
        <div class="featured-image-container gs_reveal gs_reveal_fromLeft mt-5">
          <img :src="imagePath" alt="">
        </div>
      </b-col>
      <b-col sm="12" md="5" class="description-block">
        <b-row align-v="center">
          <b-col>
            <h2 class="heading_large gs_reveal"><strong>{{ heading }}</strong></h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="gs_reveal secture-description mt-3" v-html="enhanceDescription(emphasis)"></p>
          </b-col>
        </b-row>
      </b-col>
      <b-col align-v="center" v-if="!imagePosLeft" sm="12" md="7">
        <div class="featured-image-container gs_reveal gs_reveal_fromLeft mt-3 left-img">
          <img :src="imagePath" alt="">
        </div>
      </b-col>
      <SubHeading v-if="subTitle !== undefined"
          :main=subTitle
          :tag=subTag
      ></SubHeading>
    </b-row>
  </b-container>
</template>

<script>
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import SubHeading from "./SubHeading";


export default {
  name: "SideSection",
  components: {
    SubHeading
  },
  props: ["heading", "description", "emphasis", "image", "right", "subTitle", "subTag"],
  computed: {
    idFormatted: function() {
      return 'id-'+this.heading.replaceAll(/\s/g,'').toLowerCase();
    },
    imagePath: function () {
      return require("../assets/img/" + this.image);
    },
    imagePosLeft: function () {
      if (this.right === undefined) {
        return true
      } else {
        return false;
      }
    },
  },
  mounted() {


    //*************************************************************//
    // Animations //

    function animateFrom(elem, direction) {
      direction = direction || 1;
      var x = 0,
          y = direction * 100;
      if (elem.classList.contains("gs_reveal_fromLeft")) {
        x = -100;
        y = 0;
      } else if (elem.classList.contains("gs_reveal_fromRight")) {
        x = 100;
        y = 0;
      }
      let tox = 0;
      let toy = 0;
      let alpha = 0;
      let duration = "1.25"
      let toalpha = 1;
      elem.style.opacity = "0";
      if (direction == 2) {
        x = 0;
        y = 0;

        tox = 0
        toy = -200
        alpha = 1;
        toalpha = 0;
        duration = "1.25"
        elem.style.opacity = "1";
      }
      elem.style.transform = "translate(" + x + "px, " + y + "px)";

      gsap.fromTo(elem, {x: x, y: y, autoAlpha: alpha}, {
        duration: duration,
        x: tox,
        y: toy,
        autoAlpha: toalpha,
        ease: "expo",
        overwrite: "auto"
      });
    }

    function hide(elem) {
      gsap.set(elem, {autoAlpha: 0});
    }

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () {
          animateFrom(elem)
        },
        onEnterBack: function () {
          animateFrom(elem, -1)
        },
        onLeave: function () {
          animateFrom(elem, 2)
        } // assure that the element is hidden when scrolled into view
      });
    });
  },
  methods: {
    enhanceDescription: function (st) {
      let htmlRep = "<span class='emphasis-text' style='color: var(--text-secondary-color)'>" + st + "</span>";
      const result = this.description.split('--emp-text--').join(htmlRep)
      return result;
    }
  }
}
</script>

<style scoped>
.description-block{
  z-index: 1;
}

.white {
  background-color: white;
}

.gs_reveal {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
}

h2.heading_large {
  text-align: left;
  font-size: 1.8em;
}

.emphasis-text {
  color: var(--text-secondary-color);
}

.featured-image-container img {
  max-width: 90%;
  height: auto;
  width: 100vh
}

.left-img img {
  margin-left: -13.8vh;
  z-index: -1;
  max-width: 113%;
  width: 100vh;
}

@media (min-width: 768px) {
  .featured-image-container img {
    width: 90%;
  }

  .left-img img {
    width: 113%;
  }
}

</style>
