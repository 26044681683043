<template>
  <div class="home full-height">
<!--    <img alt="Vue logo" src="../assets/logo.png">-->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <FullImgSection></FullImgSection>
    <SideSection
        :heading="$t('page2.title')"
        :description="$t('page2.description')"
        :emphasis="$t('page2.emphasis')"
        :image="$t('page2.image')"
    ></SideSection>
    <SideSection
        :heading="$t('page3.title')"
        :description="$t('page3.description')"
        :emphasis="$t('page3.emphasis')"
        :image="$t('page3.image')"
    ></SideSection>
    <CrossSection
        :heading="$t('page4.title')"
        :description="$t('page4.description')"
        :emphasis="$t('page4.emphasis')"
        :image="$t('page4.image')"
    ></CrossSection>
    <SideSection
        :heading="$t('page5.title')"
        :description="$t('page5.description')"
        :emphasis="$t('page5.emphasis')"
        :image="$t('page5.image')"
        :subTitle="$t('page5.subTitle')"
        :subTag="$t('page5.subTag')"
        right
    ></SideSection>
    <SideSection
        :heading="$t('page6.title')"
        :description="$t('page6.description')"
        :emphasis="$t('page6.emphasis')"
        :image="$t('page6.image')"
        :subTitle="$t('page6.subTitle')"
        :subTag="$t('page6.subTag')"
        right
    ></SideSection>
    <SideSection
        :heading="$t('page7.title')"
        :description="$t('page7.description')"
        :emphasis="$t('page7.emphasis')"
        :image="$t('page7.image')"
        :subTitle="$t('page7.subTitle')"
        :subTag="$t('page7.subTag')"
        right
    ></SideSection>
    <DemoSection
        :heading="$t('page8.title')"
        :description="$t('page8.description')"
        :emphasis="$t('page8.emphasis')"
    ></DemoSection>
  </div>
</template>

<script>
// @ is an alias to /src
import FullImgSection from "../components/FullImgSection";
import SideSection from "../components/SideSection";
import CrossSection from "../components/CrossSection";
import DemoSection from "../components/DemoSection";

export default {
  name: 'Home',
  components: {
    DemoSection,
    SideSection,
    FullImgSection,
    CrossSection
  },
  created(){
    document.title = "Secture 360 - Welcome"
  }
}
</script>
