<template>
  <div id="app" class="full-height">
    <Header></Header>
    <router-view/>
  </div>
</template>

<style lang="scss">

@import './assets/styles/variables.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

#app {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-primary-color);
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

html, body, #app, .full-height  {
  height: 100vh;
  margin: 0px;
}

html, body {
  margin: 0;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  font-family: "Signika Negative", sans-serif, Arial;
}

.panel {
  width: 100%;
  height: 100%;
  align-items: center;
  font-weight: 600;
  font-size: 1.5em;
  text-align: center;
  color: #D9D9D9;
  padding: 15px;
}

body {
  overflow-x: hidden;
  overflow-y: scroll;
}

.secture-description {
  color: var(--text-primary-color);
  font-weight: normal;
  font-size: var(--text-mobile-font);
  text-align: left;
}

@media (min-width: 544px) {
  .secture-description {
    font-size: var(--text-font);
  }
}

@media (min-width: 1440px) {
  .secture-description {
    font-size: var(--text-lg-font);
  }
}

.cookie-notice button {
  padding: 0;
  background-color: transparent;
  border: 0;
  font-size: 2rem;
  float: right;
  margin: -13px 0px;
}


</style>
<script>
import Header from "./components/Header";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export default {
  components: {Header},
  mounted() {

    ScrollTrigger.matchMedia({
      // desktop
      "(min-width: 760px)": function () {
        // eslint-disable-next-line no-unused-vars
        gsap.utils.toArray(".panel").forEach((panel, i) => {
          ScrollTrigger.create({
            trigger: panel,
            start: "top top",
            pin: true,
            pinSpacing: false
          });
        });

        ScrollTrigger.create({
          snap: 0.141 // snap whole page to the closest section!
        });
      }
    });
  }
}
</script>
