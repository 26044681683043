<template>
  <b-container class="panel white feature ipsSpacer_bottom_double px-5 d-flex" fluid>
    <b-row align-v="center" cols="12" class="flex-grow-1">
      <b-col md="4" sm="12">
        <b-row align-v="center">
          <b-col>
            <h2 class="heading_large gs_reveal"><strong>{{ heading }}</strong></h2>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="gs_reveal secture-description mt-3" v-html="enhanceDescription(emphasis)"></p>
          </b-col>
        </b-row>
      </b-col>
      <b-col align-v="center" md="8" sm="12">
        <ContactForm></ContactForm>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import ContactForm from "./ContactForm";

export default {
  name: "DemoSection",
  components: {ContactForm},
  props: ["heading", "description", "emphasis", "image", "right", "title"],
  computed: {
    imagePath: function () {
      return require("../assets/img/" + this.image);
    },
    imagePosLeft: function () {
      if (this.right === undefined) {
        return true
      } else {
        return false;
      }
    },
  },
  mounted() {

    //*************************************************************//
    // Animations //

    function animateFrom(elem, direction) {
      direction = direction || 1;
      var x = 0,
          y = direction * 100;
      if (elem.classList.contains("gs_reveal_fromLeft")) {
        x = -100;
        y = 0;
      } else if (elem.classList.contains("gs_reveal_fromRight")) {
        x = 100;
        y = 0;
      }
      elem.style.transform = "translate(" + x + "px, " + y + "px)";
      elem.style.opacity = "0";
      gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
        duration: 1.25,
        x: 0,
        y: 0,
        autoAlpha: 1,
        ease: "expo",
        overwrite: "auto"
      });
    }

    function hide(elem) {
      gsap.set(elem, {autoAlpha: 0});
    }

    gsap.utils.toArray(".gs_reveal").forEach(function (elem) {
      hide(elem); // assure that the element is hidden when scrolled into view

      ScrollTrigger.create({
        trigger: elem,
        onEnter: function () {
          animateFrom(elem)
        },
        onEnterBack: function () {
          animateFrom(elem, -1)
        },
        onLeave: function () {
          hide(elem)
        } // assure that the element is hidden when scrolled into view
      });
    });
  },
  methods: {
    enhanceDescription: function (st) {
      let htmlRep = "<span class='emphasis-text' style='color: var(--text-secondary-color)'>" + st + "</span>";
      const result = this.description.split('--emp-text--').join(htmlRep)
      return result;
    }
  }
}
</script>

<style scoped>
.white {
  background-color: white;
}

.gs_reveal {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
}

.secture-description {
  color: var(--text-primary-color);
  font-weight: normal;
  font-size: 15px;
  text-align: left;
}

h2.heading_large {
  text-align: left;
  font-size: 1.8em;
}

@media (min-width: 544px) {
  .secture-description {
    font-size: var(--text-font);
  }

  label {
    float: inherit;
  }
}
</style>
